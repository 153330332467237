angular
.module('cutlist')
.factory('requestInterceptor', ['$rootScope', '$q', function ($rootScope, $q) {
    let loading = 0;
    let toBroadCast = {};
    const loadingComplete = 'loading:complete';

    const IGNORE_REGEXP = /^.*.html$/;

    return {
        request: function (config) {
            if (!IGNORE_REGEXP.test(config.url)) {
                loading += 1;
            }

            return config || $q.when(config);
        },
        response: function (response) {
            if (!IGNORE_REGEXP.test(response.url)) {
                loading -= 1;
                if (loading === 0) {
                    cancelBroadCast(loadingComplete);
                    scheduleBroadCast(loadingComplete);
                }
            }

            return response || $q.when(response);
        },
        requestError: function (rejection) {
            return $q.reject(rejection);
        },
        responseError: function (rejection) {
            loading -= 1;
            if (loading === 0) {
                cancelBroadCast(loadingComplete);
                scheduleBroadCast(loadingComplete);
            }

            return $q.reject(rejection);
        }
    };

    function scheduleBroadCast (event) {
        if (toBroadCast[event]) {
            clearTimeout(toBroadCast[event]);
            delete toBroadCast[event];
        }
        toBroadCast[event] = setTimeout(function () {
            $rootScope.$broadcast(event);
            delete toBroadCast[event];
        }, 500);
    }

    function cancelBroadCast (event) {
        if (toBroadCast[event]) {
            clearTimeout(toBroadCast[event]);
            delete toBroadCast[event];
        }
    }
}]);
